import React from "react";
import Layout from "../components/layout";
import ReactMarkdown from "react-markdown";
import SubHeading from "../components/subheading";
import Heading from "../components/heading";
import { graphql } from "gatsby";
import { Seo } from "../components/seo";

export default function Page({ data }) {
	const page = data.directus.pages[0];

	return (
		<Layout>
			<section className="bg-off-white text-center py-56 relative">
				{page.subtitle && <SubHeading>{page.subtitle}</SubHeading>}
				<Heading>{page.title}</Heading>
			</section>
			<section className="bg-off-white pb-48">
				<div className="container mx-auto">
					<article className="prose lg:prose-xl mx-auto">
						<ReactMarkdown children={page.body} />
					</article>
				</div>
			</section>
		</Layout>
	);
}

export const Head = ({ location, data }) => (
	<Seo
		pathname={location.pathname}
		pageSubtitle={data.directus.pages[0].subtitle}
		pageTitle={data.directus.pages[0].title}
		title={
			data.directus.pages[0].seo_title ||
			`${data.directus.pages[0].title} - SPARK - fotografie voor cultuur, sport en entertainment`
		}
		description={data.directus.pages[0].seo_description || ``}
	>
		{!data.directus.pages[0].index_page && (
			<meta name="robots" content="noindex" />
		)}
	</Seo>
);

export const query = graphql`
	query PageQuery($slug: String) {
		directus {
			pages(filter: { slug: { _eq: $slug } }) {
				title
				subtitle
				body
				index_page
				seo_description
				seo_title
			}
		}
	}
`;
